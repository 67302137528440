import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import LinearProgress from '@material-ui/core/LinearProgress';

import AppGoGetLayout from '../../../components/AppGoGetLayout';
import HeaderSeo from '../../../components/HeaderSeo';
import JobDetails from '../JobDetails';
import JobPageAppBar from '../JobPageAppBar';
import JobPageFooter from '../JobPageFooter';
import JobPageContent from '../JobPageContent';
import MoreJobsCarousel from '../MoreJobsCarousel';

import colors from '../../../assets/colors';

import { getJobDetails } from '../../../services/axios';
import { navigate } from 'gatsby';

const LinearLoader = styled(LinearProgress)`
  && {
    background-color: ${colors.primaryLightest};

    .MuiLinearProgress-bar {
      background-color: ${colors.primary};
    }
  }
`;

const JOB_STATUS_AVAILABLE = 'open';
const ALL_POSTER_SAME_HELPER_TYPE = 'APSHT';

const Show = ({ jobId }) => {
  const [referralCode, setReferralCode] = useState(null);
  const [isFetchingJob, setIsFetchingJob] = useState(true);
  const [jobDetails, setJobDetails] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const referrer = searchParams.get('referrer');

    setReferralCode(referrer);
  }, []);

  useEffect(() => {
    const fetchJobDetails = async () => {
      const response = await getJobDetails(jobId);

      if (response?.isSuccess) {
        setJobDetails(response?.jobDetails);
        setIsFetchingJob(false);
      } else {
        navigate('/');
      }
    };

    if (jobId) {
      fetchJobDetails();
    }
  }, [jobId]);

  if (isFetchingJob) return <LinearLoader />;

  const handleClickOpenApp = label => {
    const deepLink = `${process.env.GATSBY_HOST}/gogetters/jobs/${
      jobDetails?.id
    }${referralCode ? `%3Freferrer%3D${referralCode}` : ''}&${
      process.env.GATSBY_GOGETTER_DEEPLINK_PARAMS
    }`;
    const url = `${process.env.GATSBY_GOGETTER_APP_URL}/?link=${deepLink}`;

    window &&
      window.analytics &&
      window.analytics.track('open_app_now', {
        category: jobDetails?.helper_type || '',
        label,
        page_type: 'job_details_page',
        platform: 'static'
      });

    window.open(url, '_blank');
  };

  const jobPerks = jobDetails?.perks?.split(',');

  return (
    <AppGoGetLayout logoUrl="/jobs" disableAppBar>
      <HeaderSeo title="GoGet Jobs" />
      <JobPageAppBar logoUrl="/jobs" handleClickOpenApp={handleClickOpenApp} />
      <JobPageContent
        referralCode={referralCode}
        handleClickOpenApp={handleClickOpenApp}
        isShowingBenefits
      >
        <JobDetails
          jobDetails={{ ...jobDetails, public_id: jobId }}
          jobDescription={jobDetails?.tasks && jobDetails?.tasks[0]?.name}
          gender={jobDetails?.requirement?.gender}
          dressCode={jobDetails?.requirement?.dress_code}
          interviewMethod={jobDetails?.requirement?.interview_method}
          perks={jobPerks}
          postedAt={jobDetails?.posted_at}
          isJobUnavailable={jobDetails?.status !== JOB_STATUS_AVAILABLE}
          jobType="Hourly Gig"
        />
        <MoreJobsCarousel
          title="Similar jobs"
          jobId={jobDetails?.id}
          sliderName={ALL_POSTER_SAME_HELPER_TYPE}
          isPosterFiltered
          type="Gig"
        />
      </JobPageContent>

      <JobPageFooter handleClickOpenApp={handleClickOpenApp} />
    </AppGoGetLayout>
  );
};

export default Show;
